<template>
  <div>
    <h2 class="text-2xl font-bold">TangerineLife</h2>

    <div class="grid grid-cols-2 mb-4">
      <div class="col-span-1">
        <tabs :tabs="tabs" v-model="tab" />
      </div>
      <div class="col-span-1">
        <div class="relative">
          <input
            type="search"
            name="search"
            class="border border-solid border-blue-200 rounded placeholder-blue-200 outline-none pl-10 pr-2 py-2 text-14 w-full"
            placeholder="Search for employee’s name, status, etc."
            v-model="searchQuery"
            ref="searchField"
          />
          <ion-icon
            name="search-outline"
            class="text-blue-200 absolute z-10 text-lg ml-3 left-0"
            style="top: 50%; transform: translateY(-50%);"
          />
        </div>
      </div>
    </div>

    <component
      :is="tab"
      :searchQuery="searchQuery"
      :search-field="$refs.searchField"
    />
  </div>
</template>

<script>
export default {
  components: {
    disbursed: () => import("./Disbursed"),
    sent: () => import("./Sent")
  },
  data() {
    return {
      searchQuery: "",
      tab: "sent",
      tabs: [
        { name: "disbursed", title: "Disbursed" },
        { name: "sent", title: "Sent" },
      ]
    };
  },
  watch: {
    tab(tab) {
      if (this.$route.query.tab !== tab) {
        this.$router.replace({ ...this.$route, query: { tab } });
      }
    }
  },
  mounted() {
    if (this.$route.query.tab?.match(/sent|disbursed/)) {
      this.tab = this.$route.query.tab;
    }
  }
};
</script>
